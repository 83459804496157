import React, { Component } from "react";
import {BrowserRouter as Router, Route, Link, Routes} from "react-router-dom";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./Dashboard/Dashboard";
import Login from "./Authenticate/Login";
import Home from "./Home/Home";
import Ourshop from "./Ourshop/Ourshop";
import Cart from "./Cart/Cart";
import Logout from "./Authenticate/Logout";


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token:null,
        };
    }

    switchTab = () => {
        this.setState({ token: sessionStorage.getItem('token')});
    }

    componentDidUpdate() {
        if(this.state.token == null && sessionStorage.getItem('token') != null) {
            this.setState({ token: sessionStorage.getItem('token')});
        }
    }

    render() {
        return (
            <Router>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container">
                        
                        <Link to="/" className="navbar-brand">Home</Link>
                        {this.state.token != null && this.state.token !== "null" && <Link to="/dashboard" className="navbar-brand">Dashboard</Link>}
                        {this.state.token != null && this.state.token !== "null" && <Link to="/ourshop" className="navbar-brand">Our Shop</Link>}
                        {this.state.token != null && this.state.token !== "null" && <Link to="/cart" className="navbar-brand">Cart</Link>}

                        <div className="collpase navbar-collapse">
                            <ul className="navbar-nav ms-auto">
                                {(this.state.token != null && this.state.token !== "null")?<li className="navbar-item">
                                    <Link to="/logout" className="nav-link">Logout</Link>
                                    </li>:<li className="navbar-item">
                                    <Link to="/login" className="nav-link">Login</Link>
                                </li>}
                            </ul>
                        </div>
                        
                    </div>
                </nav>
                <div className="container">
                    <br/>
                    <Routes>
                        <Route path="/" exact element={<Home/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/logout" element={<Logout setToken={() => this.setState({ token: null})} />}/>
                        <Route path="/ourshop" element={<Ourshop/>}/>
                        <Route path="/dashboard" element={<Dashboard  switchTab={this.switchTab}/>}/>
                        <Route path="/cart" element={<Cart/>}/>
                    </Routes>
                </div>
            </Router>
        );
    }
}

export default App;


