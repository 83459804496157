import React, {Component} from "react";
import {Navigate} from "react-router-dom";

class Logout extends Component {
    componentDidMount() {
        sessionStorage.setItem('token', null);
        this.props.setToken();
    }

    render() {
        return <Navigate to={{pathname:'/login'}}/>
    }
}
export default Logout;